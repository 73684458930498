.spn-link {
    color: #1890ff;
  }

.spn-link:hover {
color: #40a9ff;
cursor: pointer;
}

.ant-table-expand-icon-col {
  width: 8px;
}