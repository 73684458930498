.box-plain{
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
    font-family: Product Sans Medium Regular;
  font-style: normal;
  font-weight: normal;
  }
  @media screen and (max-width: 599px){
    .box-plain{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .card-plain{
    padding: 1rem;
    border-radius: 6px;
    background: #FDFDFD;
  }
  .header-plain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
  line-height: 24px;
  color: #8D8D8D;
  }
  .header-plain i{
    color: #1890FF;
  }
  .body-plain .coust{
    color: #1890FF;
    font-size: 16px;
  }
  .list-desc{
    list-style: none;
    margin: 0;
    padding: 0;
  font-size: 11px;
  line-height: 12px;
  text-transform: capitalize;
  color: #8D8D8D;
  }
  .list-desc li{
    display: flex;
    justify-content: space-between;
    text-align: left;
    border-bottom: 1px solid #f2f2f2;
    padding: .5rem 0;
    margin-bottom: .5rem;
  }
  .list-desc li:last-child{
    border-bottom: 0;
  }
  .btn-plain{
    padding:12px 32px;
    border-radius: 32px;
    color: #fff;
    display: block;
    background:  #1890FF;
    text-align: center;
    text-decoration: none;
  }
  .btn-plain:hover{
    cursor: pointer;
    background:  #0e7ce2;
  }
  .wraper-plain input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .wraper-plain input:checked + div{
    background: #003D7C;
    transition: all ease-in-out .1s;
  }
  .wraper-plain input:checked + div .btn-plain{
    display: none;
   }
   .wraper-plain .fa-check{
    display: none;
    opacity: 0;
   }
   .wraper-plain input:checked + div .fa-check{
    display: block;
    transition: all ease-in-out .2s;
    opacity: 1;
   }
  
   .footer-plain{
    padding: 1rem 0;
    text-align: center;
    
   }
   .footer-plain i{ 
    color: #1890FF; 
    font-size: 32px;
   }
   
   
  .wraper-plain input:checked + div .list-desc li{
    border: 0;
    padding: .5rem;
    color: #C7E4FF;
  }
  .wraper-plain input:checked + div .list-desc li:nth-child(odd){
  background: #07498E;
  border-radius: 15px;
  border: 0;
  }
  .wraper-plain input:checked + div .header-plain h3{
    color: #fff;
  }