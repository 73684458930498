:root {
  --font-family: 'Montserrat', sans-serif;
  --font-family-600: 600;
  --font-family-500: 500;
  --font-family-400: 400;
  --font-size: 1.2rem;
  --primary-color: #003d7c;
  --secondary-color: #009fe3;
  --tertiary: #1c252f;
  --success-color: #00ef8f;
  --danger-color: #fd154f;
}
.font-family {
  font-family: var(--font-family);
}
.primary-color {
  color: var(--primary-color) !important;
}
.bg-primary-custom {
  background: var(--primary-color) !important;
}
.bg-dracula {
  background: var(--tertiary);
}

.section h2 {
  color: #444;
  font-weight: var(--font-family-400);
}
.section p {
  color: #555;
}

/* custom btn */
.btn {
  /* -webkit-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.17);
    -moz-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.17);
    box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.17); */
  border-width: 2px;
}
.btn-success-custom {
  color: var(--primary-color);
  background-color: var(--success-color);
  border-color: var(--success-color);
}

.btn-primary-custom {
  color: var(--success-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-outline-primary-custom {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-outline-primary-custom:hover {
  color: #fff;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.btn-secondary-custom {
  color: #fff;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.btn-danger-custom {
  color: #fff;
  background-color: var(--danger-color);
  border-color: var(--danger-color);
}
.btn-white {
  color: #555;
  background-color: #fff;
  border-color: #fff;
}
.btn-dracula {
  color: #fff;
  background-color: var(--tertiary);
  border-color: var(--tertiary);
}

.html {
  height: 100vh;
  font-family: var(--font-family);
  background: #f9f9f9;
}

.navbar-brand img {
  transition: all ease-in-out 0.3s;
}
.menuscroll {
  transition: all ease-in-out 0.3s;
  background: var(--tertiary);
}
.menuscroll img {
  height: 25px;
  transition: all ease-in-out 0.3s;
}
/* .menuscroll .nav-link {
    color: rgb(0, 0, 0) !important;
  } */
/* .header {
  position: relative;
  height: 40vh;
} */

.carousel-caption {
  position: absolute;
  right: 15px;
  bottom: 20px;
  left: 15px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--primary-color);
  text-align: left;
}
.carousel-caption h5 {
  font-size: 2.5rem;
  font-weight: var(--font-family-600);
}
.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: var(--primary-color);
  background-clip: padding-box;
  border-top: 0;
  border-bottom: 0;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.title-home {
  font-size: 3.5rem;
  color: #00eed6;
  font-family: var(--font-family);
  text-transform: uppercase;
  font-weight: 400;
}
@media screen and (max-width: 599px) {
  .header::after {
    display: none;
  }
  .title-home {
    font-size: 2.5rem;
  }
}
.subtitle-home {
  color: rgba(255, 255, 255, 0.616);
  font-family: var(--font-family);
  font-size: 1.2rem;
}
.strong {
  font-family: var(--font-family-600);
  font-family: var(--font-family);
}
.section {
  position: relative;
  padding: 8rem 0;
}
.section-space::before {
  content: '';
  position: absolute;
  height: 5px;
  width: 100px;
  background: var(--primary-color);
  left: 50%;
  margin-left: -50px;
  bottom: 0;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--primary-color);
  font-weight: var(--font-family-600);
  font-size: 0.7rem;
}
.text-center {
  text-align: center;
}

#login {
  display: flex;
  height: 100vh;
  align-items: center;
  align-content: center;
  background: var(--primary-color);
}

#comofunciona {
  display: flex;
  align-items: center;
  padding: 2rem 0;
  background: #fff;
}
#comofunciona h3 {
  font-size: 1.1rem;
  color: var(--primary-color);
  font-weight: 500;
}
#comofunciona p {
  font-size: 1rem;
  color: #5f5f5f;
}
#comofunciona [class^='fa'] {
  color: var(--secondary-color);
}

#comofunciona .card {
  border: transparent;
  background: #fff;
  justify-content: space-between;
  transition: all ease-in-out 0.3s;
}
#comofunciona .card:hover {
  /* border: 3px solid var(--secondary-color); */
  background: #f9f9f9;
}

#plain {
  display: flex;
  min-height: 100vh;
  align-items: center;
}
#plain .card {
  border: 0;
  border-radius: 0;
  background: #f9f9f9;
}
#plain ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#plain .price span {
  font-weight: 300;
  font-size: 1.8rem;
}
#plain .price {
  display: flex;
  font-weight: var(--font-family-400);
  font-family: var(--font-family);
  font-size: 4rem;
  align-items: center;
  justify-content: center;
}
#plain ul li {
  padding: 0.5rem 0;
  color: #969696;
  font-family: var(--font-family);
}
#plain .card.bg-primary-custom {
  padding-bottom: 5rem;
  margin-top: -3rem;
}
@media screen and (max-width: 599px) {
  #plain .card.bg-primary-custom {
    margin-top: 0;
  }
}
#plain .card.bg-primary-custom .price {
  color: var(--success-color);
}
#plain .card.bg-primary-custom li {
  color: var(--success-color);
}
#plain .card .card-header {
  border: 0;
  padding: 3rem 0;
  font-weight: 100;
  color: #969696;
}
#plain .card.bg-primary-custom .card-header {
  color: var(--success-color);
}

#validade {
  display: flex;
  min-height: 60vh;
  align-items: center;
  position: relative;
}
/* #validade::before{
    content: '';
    position: absolute;
    background: #f7f7f7;
    bottom: 0;
    top: 0;
    left: 0;
    right: 60%;
    z-index: 0;
  } */
#call-action {
  display: flex;
  min-height: 30vh;
  align-items: center;
  padding: 3rem 0;
}

#call-action h2 {
  font-weight: 400;
  color: #474747;
}

#other {
  min-height: 60vh;
  display: flex;
  align-items: center;
  padding: 5rem 0;
}

#who {
  padding: 5rem 0;
  display: flex;
}
#who .item {
  background: #fff;
  -webkit-box-shadow: 0px 48px 100px -93px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 48px 100px -93px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 48px 100px -93px rgba(0, 0, 0, 0.26);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}
#who .item img {
  margin-bottom: 2rem;
  align-self: center;
}

#who h3 {
  font-size: 1.3rem;
  color: var(--primary-color);
  font-weight: 500;
}
#who p {
  font-size: 0.9rem;
}
.filter {
  list-style: none;
  margin: 0 0 2rem 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  overflow-y: hidden;
  overflow-x: auto;
  top: 50px;
}
.filter li {
  margin-right: 1rem;
  padding: 0.5rem 0.8rem;
  border-radius: 0.3rem;
  color: var(--primary-color);
}
.filter li:last-child {
  margin-right: 0;
}
.category {
  margin-bottom: 2rem;
}
.filter li.active {
  background: var(--primary-color);
  color: #fff;
}

@media screen and (max-width: 599px) {
  .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    height: 100vh;
  }

  .navbar-collapse .navbar-nav .nav-link {
    padding: 20px 10px;
  }

  @media (max-width: 768px) {
    .menuscroll .navbar-collapse {
      position: fixed;
      top: 66px;
      right: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      width: 100%;
      font-size: 16px;
      background: var(--tertiary);
    }
    .navbar-collapse {
      position: fixed;
      top: 85px;
      right: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      width: 100%;
      font-size: 16px;
      background: var(--tertiary);
    }

    .navbar-collapse.collapsing {
      height: 100vh;
      -webkit-transition: right 0.3s ease;
      -o-transition: right 0.3s ease;
      -moz-transition: right 0.3s ease;
      transition: right 0.3s ease;
      right: -100%;
    }

    .navbar-collapse.show {
      right: 0;
      -webkit-transition: right 0.3s ease-in;
      -o-transition: right 0.3s ease-in;
      -moz-transition: right 0.3s ease-in;
      transition: right 0.3s ease-in;
      background: var(--tertiary);
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  height: 30vh;
  color: var(--primary-color);
  background: #fff;
}
.footer {
  font-size: 1rem;
  padding: 8rem 0;
}
.footer h3 {
  font-size: 1rem;
  font-weight: var(--font-family-600);
}

.detail {
  display: none;
  position: fixed;
  background: #fff;
  z-index: 1021;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5rem;
}
